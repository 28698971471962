// React
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Axios
import axiosInstance from '@utils/axios';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskDiagnosisList as fetchTaskDiagnosisListService } from '@services/taskServices';
import { removeDiagnosis as removeDiagnosisService } from '@services/beneficiaryServices';

// Actions
import { clearTaskDiagnosis as clearTaskDiagnosisAction } from '@actions/taskActions';
import { showAlert as showAlertAction } from '@actions/appActions';

// Data
import { filterDiagnosis } from '@constants/tableFilters';
import { SURVEYS_NOT_COMPLETE, ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

// Utils
import { generateBeneficiaryDiagnosisPDF } from '@utils/functions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Data
const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'author', label: 'Prowadzący' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'program', label: 'Program' },
  { type: 'status', label: 'Status' },
];

// Component
const TasksDiagnosis = ({
  clearTaskDiagnosis,
  fetchDiagnosis,
  diagnosis,
  showAlert,
  removeDiagnosis,
}) => {
  const history = useHistory();

  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(null);

  useEffect(() => {
    clearTaskDiagnosis();
  }, []);

  const triggerFetchDiagnosis = useCallback(() => fetchDiagnosis(), [fetchDiagnosis]);

  useEffect(() => {
    triggerFetchDiagnosis();
  }, [triggerFetchDiagnosis]);

  const actionCells = [
    {
      column: 'Pobierz',
      action: async (row) => {
        axiosInstance.get(`/diagnoza/${row.id}`).then(({ data }) => {
          generateBeneficiaryDiagnosisPDF(data.data);
        });
      },
      button: 'pdf',
    },
  ];

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={diagnosis}
        link="/diagnoza"
        tableName="Diagnozy"
        navCells
        filters={filterDiagnosis}
        actionCells={actionCells}
        rowActions={[
          {
            name: 'zobacz',
            action: (row) => {
              history.push(`/diagnoza/${row.id}`);
            },
          },
          {
            name: 'edytuj',
            action: (row) => {
              history.push(`/diagnoza/${row.id}/edit`);
            },
          },
          {
            name: 'pobierz pdf',
            action: async (row) => {
              if (row.status === 'Zakończona') {
                axiosInstance.get(`/ankieta/${row.id}`).then(({ data }) => {
                  generateBeneficiaryDiagnosisPDF(data.data);
                });
              } else {
                showAlert(SURVEYS_NOT_COMPLETE);
              }
            },
          },
          {
            name: 'usuń',
            action: (row) => {
              if (row.beneficiary_name === 'anonimizacja') {
                showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
              } else {
                setOpenDialogConfirmRemove(row);
                // removeDiagnosis(row.id).then(() => triggerFetchDiagnosis());
              }
            },
          },
        ]}
      />
      <FloatingButton onClick={() => history.push(`/diagnoza-dodaj`)} />
      <DialogConfirm
        removeFunction={() =>
          removeDiagnosis(openDialogConfirmRemove.id).then(() => triggerFetchDiagnosis())
        }
        title="Czy na pewno chcesz usunąć diagnozę?"
        open={openDialogConfirmRemove}
        setOpenFn={() => setOpenDialogConfirmRemove(null)}
      />
    </>
  );
};

TasksDiagnosis.propTypes = {
  clearTaskDiagnosis: PropTypes.func,
  fetchDiagnosis: PropTypes.func,
  removeDiagnosis: PropTypes.func,
  showAlert: PropTypes.func,
  diagnosis: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          program_id: PropTypes.number,
          program_name: PropTypes.string,
          id: PropTypes.number,
          diagnosis_name: PropTypes.string,
          beneficiary_first_name: PropTypes.string,
          beneficiary_last_name: PropTypes.string,
          is_diagnosis_conducted: PropTypes.bool,
          date_execution: PropTypes.string,
          diagnosis_author: PropTypes.string,
        }),
      ),
    }),
  ),
};

TasksDiagnosis.defaultProps = {
  clearTaskDiagnosis: null,
  fetchDiagnosis: null,
  removeDiagnosis: null,
  diagnosis: [],
  showAlert: null,
};

const mapStateToProps = ({ taskReducer }) => ({
  diagnosis: taskReducer.diagnosis,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskDiagnosis: () => dispatch(clearTaskDiagnosisAction()),
  fetchDiagnosis: () => dispatch(fetchTaskDiagnosisListService()),
  removeDiagnosis: (id) => dispatch(removeDiagnosisService(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksDiagnosis);
