// React
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskIPPList as fetchTaskIPPListService } from '@services/taskServices';
import { removeIPP as removeIPPService } from '@services/beneficiaryServices';

// Actions
import { clearTaskIPP as clearTaskIPPAction } from '@actions/taskActions';

// Utils
import { generateBeneficiaryIPPPDF } from '@utils/functions';

// Data
import { filterIPP } from '@constants/tableFilters';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

import { showAlert as showAlertAction } from '@actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Data
const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'author', label: 'Prowadzący' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'program', label: 'Program' },
  { type: 'status', label: 'Status' },
];

const actionCells = [
  {
    column: 'Pobierz',
    action: (row) => {
      generateBeneficiaryIPPPDF(row);
    },
    button: 'pdf',
  },
];

// Component
const TasksIPP = ({ clearTaskIPP, fetchIPP, removeIPP, showAlert, ipp }) => {
  const history = useHistory();
  const triggerFetchIPP = useCallback(() => fetchIPP(), [fetchIPP]);

  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(null);

  useEffect(() => {
    clearTaskIPP();
  }, []);

  useEffect(() => {
    triggerFetchIPP();
  }, [triggerFetchIPP]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={ipp}
        link="/indywidualny-plan-pracy"
        navCells
        tableName="Indywidualne plany pracy"
        filters={filterIPP}
        actionCells={actionCells}
        rowActions={[
          {
            name: 'zobacz',
            action: (row) => {
              history.push(`/indywidualny-plan-pracy/${row.id}`);
            },
          },
          {
            name: 'edytuj',
            action: (row) => {
              history.push(`/indywidualny-plan-pracy/${row.id}/edit`);
            },
          },
          {
            name: 'usuń',
            action: (row) => {
              if (row.beneficiary_name === 'anonimizacja') {
                showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
              } else {
                setOpenDialogConfirmRemove(row);
                // removeIPP(row.id).then(() => triggerFetchIPP());
              }
            },
          },
        ]}
      />
      <FloatingButton onClick={() => history.push(`/indywidualny-plan-pracy-dodaj`)} />
      <DialogConfirm
        removeFunction={() => removeIPP(openDialogConfirmRemove.id).then(() => triggerFetchIPP())}
        title="Czy na pewno chcesz usunąć plan pracy?"
        open={openDialogConfirmRemove}
        setOpenFn={() => setOpenDialogConfirmRemove(null)}
      />
    </>
  );
};

TasksIPP.propTypes = {
  clearTaskIPP: PropTypes.func,
  fetchIPP: PropTypes.func,
  removeIPP: PropTypes.func,
  showAlert: PropTypes.func,
  ipp: PropTypes.arrayOf(
    PropTypes.shape({
      program_id: PropTypes.number,
      program_name: PropTypes.string,
      id: PropTypes.number,
      plan_name: PropTypes.string,
      beneficiary_first_name: PropTypes.string,
      beneficiary_last_name: PropTypes.string,
      is_prepared: PropTypes.bool,
      start_date: PropTypes.string,
      entry_modified_on: PropTypes.string,
      plan_author: PropTypes.string,
    }),
  ),
};

TasksIPP.defaultProps = {
  clearTaskIPP: null,
  fetchIPP: null,
  removeIPP: null,
  showAlert: null,
  ipp: [],
};

const mapStateToProps = ({ taskReducer }) => ({
  ipp: taskReducer.ipp,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskIPP: () => dispatch(clearTaskIPPAction()),
  fetchIPP: (id) => dispatch(fetchTaskIPPListService(id)),
  removeIPP: (id) => dispatch(removeIPPService(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksIPP);
