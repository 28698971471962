// React
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramDiagnosis } from '@services/programServices';
import { removeDiagnosis as removeDiagnosisService } from '@services/beneficiaryServices';

// Actions
import { clearProgramDiegnosis as clearProgramDiegnosisAction } from '@actions/programActions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Data
import { filterDiagnosis } from '@constants/tableFilters';

import { showAlert as showAlertAction } from '@actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'status', label: 'Diagnoza przeprowadzona' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'author', label: 'Osoba przeprowadzająca' },
];

// Components
const ProgramDiagnosis = ({
  clearProgramDiegnosis,
  fetchDiagnosis,
  removeDiagnosis,
  showAlert,
  data,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { diagnosis } = data;

  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(null);

  useEffect(() => {
    clearProgramDiegnosis();
  }, []);

  const triggerFetchDiagnosis = useCallback((myid) => fetchDiagnosis(myid), [fetchDiagnosis]);

  useEffect(() => {
    triggerFetchDiagnosis(id);
  }, [id, triggerFetchDiagnosis]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={diagnosis}
        filters={filterDiagnosis.filter((item) => item.key !== 'program')}
        isnav
        link="/diagnoza"
        tableName="Diagnozy"
        rowActions={[
          {
            name: 'zobacz',
            action: (row) => {
              history.push(`/diagnoza/${row.id}`);
            },
          },
          {
            name: 'edytuj',
            action: (row) => {
              history.push(`/diagnoza/${row.id}/edit`);
            },
          },
          {
            name: 'usuń',
            action: (row) => {
              if (row.beneficiary_name === 'anonimizacja') {
                showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
              } else {
                setOpenDialogConfirmRemove(row);
                // removeDiagnosis(row.id).then(() => triggerFetchDiagnosis(id));
              }
            },
          },
        ]}
      />
      <FloatingButton onClick={() => history.push(`/diagnoza-dodaj/program/${id}`)} />
      <DialogConfirm
        removeFunction={() =>
          removeDiagnosis(openDialogConfirmRemove.id).then(() => triggerFetchDiagnosis(id))
        }
        title="Czy na pewno chcesz usunąć diagnozę?"
        open={openDialogConfirmRemove}
        setOpenFn={() => setOpenDialogConfirmRemove(null)}
      />
    </>
  );
};

ProgramDiagnosis.propTypes = {
  clearProgramDiegnosis: PropTypes.func,
  fetchDiagnosis: PropTypes.func,
  removeDiagnosis: PropTypes.func,
  showAlert: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    diagnosis: PropTypes.arrayOf(
      PropTypes.shape({
        beneficiary_first_name: PropTypes.string,
        beneficiary_last_name: PropTypes.string,
        date_execution: PropTypes.string,
        diagnosis_author: PropTypes.string,
        is_conducted: PropTypes.bool,
      }),
    ),
  }),
};

ProgramDiagnosis.defaultProps = {
  clearProgramDiegnosis: null,
  fetchDiagnosis: null,
  removeDiagnosis: null,
  showAlert: null,
  data: {
    program_id: null,
    program_name: '',
    program_diagnosis: [],
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.diagnosis,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramDiegnosis: () => dispatch(clearProgramDiegnosisAction()),
  fetchDiagnosis: (id) => dispatch(fetchProgramDiagnosis(id)),
  removeDiagnosis: (id) => dispatch(removeDiagnosisService(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDiagnosis);
