// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchBeneficiaryIPP as fetchBeneficiaryIPPService,
  removeIPP as removeIPPService,
} from '@services/beneficiaryServices';

// Actions
import { clearBeneficiaryIPP as clearBeneficiaryIPPAction } from '@actions/beneficiaryActions';

// Utils
import { generateBeneficiaryIPPPDF } from '@utils/functions';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Elements
import { Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Table from '@components/organisms/Table/Table';
import MyAccordion from '@components/atoms/Accordion/Accordion';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
// import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

import { showAlert as showAlertAction } from '@actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

// Styles
import './Ipp.css';
import { StyledButton, StyledWrapper, StyledTitle, StyledBox } from './BeneficiaryIPP.styles';

// Data
const headCells = [
  { type: 'title', label: 'Nazwa' },
  { type: 'who_added', label: 'Prowadzący' },
  { type: 'status', label: 'Status' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'program', label: 'Program' },
];

const actionCells = [
  {
    column: 'Pobierz',
    action: (row) => {
      generateBeneficiaryIPPPDF(row);
    },
    button: 'pdf',
  },
];

// Component
const BeneficiaryIPP = ({
  clearBeneficiaryIPP,
  fetchIPP,
  removeIPP,
  ipps,
  showAlert,
  beneficiary,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);

  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(null);

  useEffect(() => {
    clearBeneficiaryIPP();
  }, []);

  const triggerFetchIPP = useCallback((myid) => fetchIPP(myid), [fetchIPP]);

  useEffect(() => {
    triggerFetchIPP(id);
  }, [id, triggerFetchIPP]);

  const showAccordion = (ended) =>
    !((!isActiveProgram && !isEndProgram) || (isActiveProgram && !ended) || (isEndProgram && ended))
      ? 'true'
      : 'false';

  return (
    <>
      <div className="ipp">
        <>
          <StyledTitle>
            <Heading.Subtitle1>Historia Planów Pracy</Heading.Subtitle1>
          </StyledTitle>
          <StyledWrapper className="notes" container spacing={2}>
            <Grid item xs={2}>
              <Checkbox
                label="Aktualne"
                isEditable={!!true}
                value={isActiveProgram}
                onChange={() => setActiveProgram(!isActiveProgram)}
              />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                label="Zakończone"
                isEditable={!!true}
                value={isEndProgram}
                onChange={() => setEndProgram(!isEndProgram)}
              />
            </Grid>
          </StyledWrapper>
          {ipps && ipps.length ? (
            ipps.map(({ id: APIProgramID, cwp_id, ended, name, ipps: ipp }) => {
              return (
                <MyAccordion key={APIProgramID} title={name} hide={showAccordion(ended)}>
                  <StyledBox>
                    <Table
                      headCells={headCells}
                      data={ipp}
                      noSort
                      actionCells={actionCells}
                      rowActions={[
                        {
                          name: 'zobacz',
                          action: (row) => {
                            history.push(`/indywidualny-plan-pracy/${row.id}`);
                          },
                        },
                        {
                          name: 'edytuj',
                          action: (row) => {
                            if (beneficiary && beneficiary.name === 'anonimizacja') {
                              showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                            } else {
                              history.push(`/indywidualny-plan-pracy/${row.id}/edit`);
                            }
                          },
                        },
                        {
                          name: 'usuń',
                          action: (row) => {
                            if (beneficiary && beneficiary.name === 'anonimizacja') {
                              showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                            } else {
                              setOpenDialogConfirmRemove(row);
                              // removeIPP(row.id, id);
                            }
                          },
                        },
                      ]}
                    />
                    {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                      <StyledButton
                        startIcon={<AddCircleIcon />}
                        onClick={() => {
                          history.push(`/indywidualny-plan-pracy-dodaj/${cwp_id}`);
                        }}
                      >
                        Utwórz Indywidualny Plan Pracy
                      </StyledButton>
                    )}
                    <DialogConfirm
                      removeFunction={() => removeIPP(openDialogConfirmRemove.id, id)}
                      title="Czy na pewno chcesz usunąć plan pracy?"
                      open={openDialogConfirmRemove}
                      setOpenFn={() => setOpenDialogConfirmRemove(null)}
                    />
                  </StyledBox>
                </MyAccordion>
              );
            })
          ) : (
            <Grid item xs={12} container justifyContent="center">
              <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
            </Grid>
          )}
        </>
      </div>
      {/* <FloatingButton onClick={() => history.push(`/indywidualny-plan-pracy-dodaj`)} /> */}
    </>
  );
};

BeneficiaryIPP.propTypes = {
  clearBeneficiaryIPP: PropTypes.func,
  fetchIPP: PropTypes.func,
  removeIPP: PropTypes.func,
  ipps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      whoAdded: PropTypes.string,
      dateStart: PropTypes.string,
      datePeriod: PropTypes.string,
      program: PropTypes.string,
    }),
  ),
  showAlert: PropTypes.func,
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryIPP.defaultProps = {
  clearBeneficiaryIPP: null,
  fetchIPP: null,
  removeIPP: null,
  ipps: [],
  showAlert: null,
  beneficiary: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  ipps: beneficiaryReducer.ipps,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryIPP: () => dispatch(clearBeneficiaryIPPAction()),
  fetchIPP: (id) => dispatch(fetchBeneficiaryIPPService(id)),
  removeIPP: (id, beneficiary_id) => dispatch(removeIPPService(id, beneficiary_id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryIPP);
