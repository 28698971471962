// React
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramIPP } from '@services/programServices';
import { removeIPP as removeIPPService } from '@services/beneficiaryServices';

// Actions
import { clearProgramIPP as clearProgramIPPAction } from '@actions/programActions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Data
import { filterIPP } from '@constants/tableFilters';

import { showAlert as showAlertAction } from '@actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'status', label: 'Status' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'modified_on', label: 'Data ostatniej edycji' },
  { type: 'last_modified_by', label: 'Ostatnia osoba wprowadzająca' },
];

// Component
const ProgramIPP = ({ clearProgramIPP, showAlert, removeIPP, fetchData, data }) => {
  const { id } = useParams();
  const history = useHistory();
  const { ipps } = data;

  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(null);

  useEffect(() => {
    clearProgramIPP();
  }, []);

  const triggerFetchData = useCallback((myid) => fetchData(myid), [fetchData]);

  useEffect(() => {
    triggerFetchData(id);
  }, [id, triggerFetchData]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={ipps}
        filters={filterIPP.filter((item) => item.key !== 'program')}
        isnav
        link="/indywidualny-plan-pracy"
        tableName="Indywidualne plany pracy"
        rowActions={[
          {
            name: 'zobacz',
            action: (row) => {
              history.push(`/indywidualny-plan-pracy/${row.id}`);
            },
          },
          {
            name: 'edytuj',
            action: (row) => {
              history.push(`/indywidualny-plan-pracy/${row.id}/edit`);
            },
          },
          {
            name: 'usuń',
            action: (row) => {
              if (row.beneficiary_name === 'anonimizacja') {
                showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
              } else {
                setOpenDialogConfirmRemove(row);
                // removeIPP(row.id).then(() => triggerFetchData(id));
              }
            },
          },
        ]}
      />
      <FloatingButton
        onClick={() => history.push(`/indywidualny-plan-pracy-dodaj/program/${id}`)}
      />
      <DialogConfirm
        removeFunction={() =>
          removeIPP(openDialogConfirmRemove.id).then(() => triggerFetchData(id))
        }
        title="Czy na pewno chcesz usunąć plan pracy?"
        open={openDialogConfirmRemove}
        setOpenFn={() => setOpenDialogConfirmRemove(null)}
      />
    </>
  );
};

ProgramIPP.propTypes = {
  clearProgramIPP: PropTypes.func,
  fetchData: PropTypes.func,
  removeIPP: PropTypes.func,
  showAlert: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ipps: PropTypes.arrayOf(
      PropTypes.shape({
        beneficiary_first_name: PropTypes.string,
        beneficiary_last_name: PropTypes.string,
        is_prepared: PropTypes.bool,
        date_start: PropTypes.string,
        modified_on: PropTypes.string,
        ipp_author: PropTypes.string,
      }),
    ),
  }),
};

ProgramIPP.defaultProps = {
  clearProgramIPP: null,
  fetchData: null,
  removeIPP: null,
  showAlert: null,
  data: {
    id: null,
    name: '',
    ipps: [],
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.ipp,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramIPP: () => dispatch(clearProgramIPPAction()),
  fetchData: (id) => dispatch(fetchProgramIPP(id)),
  removeIPP: (id) => dispatch(removeIPPService(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramIPP);
